import React, { useState } from "react";
import { Link } from "gatsby";
import {
	SiApplepodcasts,
	SiGooglepodcasts,
	SiPocketcasts,
} from "react-icons/si";
import {
	FaSpotify,
	FaRssSquare,
	FaFacebookSquare,
	FaTwitterSquare,
	FaShareAlt,
} from "react-icons/fa";
import SharingModal from "./sharingModal";
import { Twitter, Facebook } from "react-social-sharing";
import { Acast } from "svg";

/* Listen
============================================================================= */

const Listen = ({ podcast }) => {
	// Config
	const [modalOpen, setModalOpen] = useState(false);

	// Render Apple
	const renderApple = () => {
		try {
			if (podcast.podcast_providers.apple_podcasts) {
				return (
					<a
						role="listitem"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Apple Podcasts"
						className="flex flex-row items-center mb-4 mr-6 rounded-md"
						href={podcast.podcast_providers.apple_podcasts}
					>
						<SiApplepodcasts size={30} />
						<h4 className="pl-2 text-lg font-medium font-body">
							Apple Podcasts
						</h4>
					</a>
				);
			}
		} catch (error) {
			return null;
		}
	};

	// Render Spotify
	const renderSpotify = () => {
		try {
			if (podcast.podcast_providers.spotify_podcasts) {
				return (
					<a
						role="listitem"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Spotify Podcasts"
						className="flex flex-row items-center mb-4 mr-6 rounded-md"
						href={podcast.podcast_providers.spotify_podcasts}
					>
						<FaSpotify size={30} />
						<h4 className="pl-2 text-lg font-medium font-body">
							Spotify
						</h4>
					</a>
				);
			}
		} catch (error) {
			return null;
		}
	};

	// Render Google
	const renderGoogle = () => {
		try {
			if (podcast.podcast_providers.google_podcasts) {
				return (
					<a
						role="listitem"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Google Podcasts"
						className="flex flex-row items-center mb-4 mr-6 rounded-md"
						href={podcast.podcast_providers.google_podcasts}
					>
						<SiGooglepodcasts size={30} />
						<h4 className="pl-2 text-lg font-medium font-body">
							Google Podcasts
						</h4>
					</a>
				);
			}
		} catch (error) {
			return null;
		}
	};

	// Render Pocket Casts
	const renderPocketCasts = () => {
		try {
			if (podcast.podcast_providers.pocket_casts) {
				return (
					<a
						role="listitem"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Pocket Casts"
						className="flex flex-row items-center mb-4 mr-6 rounded-md"
						href={podcast.podcast_providers.pocket_casts}
					>
						<SiPocketcasts size={30} />
						<h4 className="pl-2 text-lg font-medium font-body">
							Pocket Casts
						</h4>
					</a>
				);
			}
		} catch (error) {
			return null;
		}
	};

	// Render Acast
	const renderAcast = () => {
		try {
			if (podcast.podcast_providers.acast_podcasts) {
				return (
					<a
						role="listitem"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Acast"
						className="flex flex-row items-center mb-4 mr-6 rounded-md"
						href={podcast.podcast_providers.acast_podcasts}
					>
						<Acast />
						<h4 className="pl-1 text-lg font-medium font-body">
							Acast
						</h4>
					</a>
				);
			}
		} catch (error) {
			return null;
		}
	};

	// Render
	return (
		<>
			<SharingModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
			<ul
				role="list"
				aria-label="Podcast Listening Options"
				className="flex flex-row flex-wrap items-start justify-start w-full col-span-5 pt-8 "
			>
				<p className="hidden mr-6 text-xl font-bold tiresias">Listen</p>
				{renderApple()}
				{renderSpotify()}
				{renderGoogle()}
				{renderPocketCasts()}
				{renderAcast()}
				<a
					role="listitem"
					aria-label="RSS Feed"
					className="flex flex-row items-center hidden mb-4 mr-6 rounded-md"
					href="/rss.xml"
				>
					<FaRssSquare size={30} />
					<h4 className="pl-2 text-lg font-medium font-body">RSS</h4>
				</a>

				<button
					role="listitem"
					aria-label="Share"
					className="flex flex-row items-center mb-4 mr-6 rounded-md"
					onClick={() => setModalOpen(true)}
				>
					<FaShareAlt size={30} />
					<h4 className="pl-2 text-lg font-medium font-body">
						Share
					</h4>
				</button>
			</ul>
		</>
	);
};

/* Export
============================================================================= */

export default Listen;
