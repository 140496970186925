import React, { useState, useEffect, useRef } from "react";
import ReactModal from "react-modal";
import {
	FacebookShareButton,
	FacebookIcon,
	TwitterShareButton,
	TwitterIcon,
} from "react-share";
import { AiOutlineClose } from "react-icons/ai";

/* Sharing Modal
============================================================================= */

const SharingModal = ({ modalOpen, setModalOpen }) => {
	// Config
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(true);
	}, []);

	// Render
	if (!mounted) return null;
	return (
		<ReactModal
			isOpen={modalOpen}
			onRequestClose={() => setModalOpen(false)}
			style={{
				overlay: {
					zIndex: 100000,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					padding: "25px",
				},
				content: {
					position: "relative",
					width: "100%",
					maxWidth: "500px",
					inset: "0px",
				},
			}}
		>
			<h2 className="w-full pb-6 text-4xl leading-none tracking-wide text-black font-display">
				Share
			</h2>
			<ul className="flex flex-col items-start justify-start w-full">
				<FacebookShareButton url={window.location.href}>
					<button
						role="listitem"
						aria-label="Share to Facebook"
						className="flex flex-row items-center mb-4 mr-6 rounded-md"
					>
						<FacebookIcon size={30} round />
						<h4 className="pl-2 text-lg font-medium font-body">
							Facebook
						</h4>
					</button>
				</FacebookShareButton>
				<TwitterShareButton url={window.location.href}>
					<button
						role="listitem"
						aria-label="Share to Twitter"
						className="flex flex-row items-center mb-4 mr-6 rounded-md"
					>
						<TwitterIcon size={30} round />
						<h4 className="pl-2 text-lg font-medium font-body">
							Twitter
						</h4>
					</button>
				</TwitterShareButton>
			</ul>
			<button
				className="absolute text-lg font-bold font-body top-6 right-4"
				onClick={() => setModalOpen(false)}
			>
				<AiOutlineClose size={30} />
			</button>
		</ReactModal>
	);
};

/* Export
============================================================================= */

export default SharingModal;
