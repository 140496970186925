import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";
import { kebabCase, getGatsbyImage } from "helpers";

/* Podcast Item
============================================================================= */

const PodcastItem = ({ podcast }) => {
	// Config

	// Render
	return (
		<Link
			aria-label="Podcast Item"
			aria-describedby={`${podcast.name} with ${podcast.hosts[0].name}`}
			role="listitem"
			to={`/podcasts/${kebabCase(podcast.name)}`}
			className="w-full group hover focus:scale-105 rounded-sm outline-none relative"
		>
			<Image
				fluid={getGatsbyImage(podcast.image)}
				alt={podcast.image.alt}
				className="square-image shadow-lg duration-200 ease-in-out rounded-md transition-shadow group-focus outline-none"
				placeholderStyle={{
					filter: "blur(50px)",
				}}
				style={{
					overflow: "hidden",
				}}
				imgStyle={{
					objectFit: "cover",
					objectPosition: "50% 50%",
				}}
			/>
			<h3
				id={`${podcast.name}`}
				aria-label={`${podcast.name} with ${podcast.hosts[0].name}`}
				className="font-body font-bold pt-4 text-xl tracking-wide leading-none"
			>
				{podcast.name} <br />
				<span className="font-body text-sm text-gold font-bold tracking-wider leading-none">
					with {podcast.hosts[0].name}
				</span>{" "}
				<br />
				<span className="font-body text-sm text-black font-bold tracking-wider leading-none">
					{podcast.tagline}
				</span>
			</h3>
		</Link>
	);
};

/* Export
============================================================================= */

export default PodcastItem;
