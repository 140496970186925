import React, { useState } from "react";
import BlockContent from "@sanity/block-content-to-react";
import YouTube from "react-youtube";
import _ from "lodash";

/* Serializers
============================================================================= */

const serializers = {
	types: {
		block: (props) => BlockContent.defaultSerializers.types.block(props),
		youtube: ({ node }) => {
			const { youtube_id } = node;

			return (
				<div className="container flex items-center justify-center w-full mx-auto">
					<div className="w-full 2xl:w-2/3">
						<YouTube
							videoId={youtube_id}
							containerClassName="youtube-container"
						/>
					</div>
				</div>
			);
		},
		image_grid: ({ node }) => {
			const { images } = node;
			return (
				<div className="grid grid-cols-1 gap-6 md:grid-cols-2">
					{images.map((image) => {
						return (
							<img
								key={image.image.image.asset.url}
								src={image.image.image.asset.url}
								className={`col-span-1 md:col-span-${image.columns}`}
							/>
						);
					})}
				</div>
			);
		},
	},
};

/* Export
============================================================================= */

export default serializers;
