import React, { useEffect } from "react";
import { SEO } from "components";
import Helmet from "react-helmet";
import Header from "./header";
import Footer from "./footer";
import Sidebar from "./sidebar";
import Acast from "./acast";
import AcastMobile from "./acastMobile";
import { useMediaQuery } from "react-responsive";
import "styles/styles.scss";

/* Layout
============================================================================= */

const Layout = ({ children }) => {
	// Config
	const isMobile = useMediaQuery({
		maxDeviceWidth: 768,
	});

	// Render
	return (
		<>
			<Helmet>
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-KY20C3PMB9"
				></script>
				<script>
					{`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-KY20C3PMB9');`}
				</script>
			</Helmet>
			<Header />
			<div className="flex flex-row mx-auto wrapper main-horizontal-padding">
				<main
					id="main"
					role="main"
					className="flex-grow pt-16 bg-white md:pt-36 pattern-cross-dots"
				>
					{children}
				</main>
				<Sidebar />
			</div>
			{isMobile ? <AcastMobile /> : null}
			<Footer />
		</>
	);
};

/* Export
============================================================================= */

export default Layout;
