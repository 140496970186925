import React, { useEffect, useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Logo from "./logo";
import Navigation from "./navigation";
import NavigationMobile from "./navigationMobile";
import Player from "./player";
import { GiHamburgerMenu } from "react-icons/gi";
import MobilePlayer from "./mobilePlayer";
import Acast from "../acast";
import useMenu from "state/useMenu";
import { useMediaQuery } from "react-responsive";

/* Header
============================================================================= */

const Header = () => {
	// Config
	const { setShowMenu } = useMenu();
	const isMobile = useMediaQuery({
		maxDeviceWidth: 768,
	});
	const data = useStaticQuery(graphql`
		query HeaderQuery {
			logo: file(relativePath: { eq: "logo.png" }) {
				childImageSharp {
					fluid(maxWidth: 1000) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
		}
	`);

	// Render
	return (
		<header
			role="banner"
			id="header"
			className="fixed top-0 flex flex-col items-start justify-start w-full bg-white shadow-lg"
		>
			<div className="relative flex flex-row items-center justify-between w-full mx-auto bg-white md:h-24 h-14 wrapper header-horizontal-padding">
				<Logo image={data.logo.childImageSharp.fluid} />
				{isMobile ? null : <Acast />}
				<button
					onClick={() => setShowMenu(true)}
					className="block md:hidden"
				>
					<GiHamburgerMenu size={25} color="black" />
				</button>
			</div>
			<NavigationMobile />
			<div className="hidden w-full h-12 bg-black md:block">
				<div className="grid h-full grid-cols-4 mx-auto wrapper ">
					<Navigation />
				</div>
			</div>
		</header>
	);
};

/* Export
============================================================================= */

export default Header;
