import React, { useEffect } from "react";
import { Link } from "gatsby";
import useMenu from "state/useMenu";

/* Navigation Link
============================================================================= */

const NavigationLink = ({ label, to, children }) => {
	// Config
	const { showMenu, setShowMenu } = useMenu();

	// Render
	return (
		<li
			role="listitem"
			aria-label={label}
			className="header-navigation-link font-body text-base align-middle font-semibold tracking-wider antialiased text-white uppercase relative relative z-50"
		>
			<Link to={to} onClick={() => setShowMenu(false)}>
				{label}
				{children ? (
					<ul
						role="list"
						className="hidden dropdown-menu absolute left-0 pb-2 pt-5 pl-3 pr-4 flex flex-col items-start justify-start bg-black space-y-2 z-10"
						style={{ top: "1.5rem", left: "-0.55rem" }}
					>
						{children}
					</ul>
				) : null}
			</Link>
		</li>
	);
};

/* Export
============================================================================= */

export default NavigationLink;
