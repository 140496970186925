import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import NavigationLink from "./navigationLink";
import { FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import useMenu from "state/useMenu";

/* Navigation Mobile
============================================================================= */

const NavigationMobile = ({ children }) => {
	// Config
	const { showMenu, setShowMenu } = useMenu();

	// Render
	return (
		<nav
			aria-label="Main"
			className="lg:hidden flex flex-col items-center justify-start fixed top-0 h-full w-full overflow-hidden bg-black transition-all"
			style={{
				left: showMenu ? "0vw" : "100vw",
				transitionDuration: "0ms",
			}}
		>
			<button onClick={() => setShowMenu(false)}>
				<MdClose
					size={25}
					color="white"
					className="absolute top-4 right-4"
				/>
			</button>
			<ul className="space-y-6 h-full flex flex-col items-center justify-center">
				<NavigationLink to="/podcasts" label="Podcasts" />
				<NavigationLink to="/about" label="About" />
				<NavigationLink to="/accessibility" label="Accessibility" />
				<NavigationLink to="/contact" label="Contact" />
				<NavigationLink to="/sponsors" label="Sponsors" />
				<NavigationLink to="/news" label="News" />
				<NavigationLink to="/newsletters" label="Sign Up" />
			</ul>
		</nav>
	);
};

/* Export
============================================================================= */

export default NavigationMobile;
