import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";
import { kebabCase, getGatsbyImage, getDate } from "helpers";
import usePlayer from "state/usePlayer";
import { FaRegPlayCircle } from "react-icons/fa";
import { ImPause2, ImFileText2 } from "react-icons/im";
import { VscLoading } from "react-icons/vsc";
import _ from "lodash";

/* Trailer Item
============================================================================= */

const TrailerItem = ({ podcast, noTranscript }) => {
	// Config
	const {
		setEpisode,
		episodes,
		setPlaying,
		addEpisode,
		playing,
		setLoading,
		loading,
		episode: episodeIndex,
	} = usePlayer();

	// Render Playing
	const renderPlaying = () => {
		if (
			episodes[episodeIndex].episode === podcast.trailer_id &&
			episodes[episodeIndex].show === podcast.podcast_id &&
			loading
		) {
			return (
				<>
					<VscLoading
						color="black"
						size={30}
						className="animate-spin"
					/>
					<span className="pl-2 font-semibold font-body">
						LISTEN NOW
					</span>
				</>
			);
		}
		if (
			episodes[episodeIndex].episode === podcast.trailer_id &&
			episodes[episodeIndex].show === podcast.podcast_id &&
			playing
		) {
			return (
				<>
					<ImPause2 color="black" size={30} />
					<span className="pl-2 font-semibold font-body">
						LISTEN NOW
					</span>
				</>
			);
		}
		return (
			<>
				<FaRegPlayCircle color="black" size={30} />
				<span className="pl-2 font-semibold font-body">LISTEN NOW</span>
			</>
		);
	};

	// Render
	if (!podcast.trailer_id) return null;
	return (
		<div className="w-full padding-top">
			<div
				aria-label={`Introducing ${podcast.name}`}
				className="flex flex-col items-start justify-start w-full py-4 pl-5 pr-6 bg-gray-100 rounded-md outline-none group"
			>
				<div className="text-sm tracking-wider text-gray-900 uppercase font-body">
					<span className="font-semibold text-gold">
						{podcast.name}
					</span>{" "}
					&bull; Trailer
				</div>
				<h3 className="pt-1 pb-2 text-3xl font-semibold tracking-wide font-display">
					{`Introducing ${podcast.name}`}
				</h3>
				<p className="w-full pb-4 leading-relaxed font-body">
					{podcast.blurb}
				</p>
				<div className="flex flex-row items-center justify-start w-full">
					<button
						aria-label="Listen Now"
						style={{ outline: "0px" }}
						className="flex flex-row items-center justify-start mr-4 outline-none focus"
						onClick={() => {
							const player = document.getElementById(
								"acast-player"
							);
							if (
								episodes[episodeIndex].episode ===
									podcast.trailer_id &&
								episodes[episodeIndex].show ===
									podcast.podcast_id &&
								playing
							) {
								setPlaying(false);
								const message = {
									eventName: "postmessage:do:pause",
								};
								player.contentWindow.postMessage(
									JSON.stringify(message),
									"*"
								);
								return;
							}
							const index = _.findIndex(episodes, (el) => {
								return (
									el.show === podcast.podcast_id &&
									el.episode === podcast.trailer_id
								);
							});
							if (index === 0 || index) {
								setEpisode(index);
							} else {
								addEpisode({
									show: podcast.podcast_id,
									episode: podcast.trailer_id,
								});
								setEpisode(episodes.length);
							}
							setLoading(true);
							const message = {
								eventName: "postmessage:do:play",
								data: {
									show: episodes[index].show,
									episode: episodes[index].episode,
								},
							};
							setPlaying(true);
							player.contentWindow.postMessage(
								JSON.stringify(message),
								"*"
							);
						}}
					>
						{renderPlaying()}
					</button>
					{!noTranscript ? (
						<Link
							to={`/podcasts/${kebabCase(
								podcast.name
							)}/trailer-transcript`}
							className="relative flex flex-row items-center justify-start font-semibold font-body"
						>
							<span className="hidden mr-4 text-lg">&bull;</span>
							<ImFileText2 color="black" size={26} />
							<span className="pl-2 font-body">TRANSCRIPT</span>
						</Link>
					) : null}
				</div>
			</div>
		</div>
	);
};

/* Export
============================================================================= */

export default TrailerItem;
