import React, { useEffect } from "react";
import usePlayer from "state/usePlayer";
import { useStaticQuery, graphql } from "gatsby";

/* Acast Mobile
============================================================================= */

const AcastMobile = ({ children }) => {
	// Config
	const { url, setLoading } = usePlayer();
	const data = useStaticQuery(graphql`
		{
			allSanityEpisode(
				sort: { order: DESC, fields: _createdAt }
				limit: 1
			) {
				nodes {
					podcast {
						podcast_id
					}
					episode_id
				}
			}
		}
	`);

	useEffect(() => {
		const player = document.getElementById("acast-player");
		window.addEventListener(
			"message",
			(event) => {
				if (event.data.eventName === "postmessage:on:play") {
					setLoading(false);
				}
			},
			false
		);
	}, []);

	// Render
	return (
		<div className="fixed bottom-0 z-50 flex flex-col items-center justify-end w-screen h-screen pointer-events-none">
			<iframe
				aria-hidden="true"
				id="acast-player"
				title="Embed Player"
				width="350px"
				height="96px"
				src={`https://embed.acast.com/${data.allSanityEpisode.nodes[0].podcast.podcast_id}/${data.allSanityEpisode.nodes[0].episode_id}?accentColor=fff&bgColor=000&logo=false&secondaryColor=b4841f&subscribe=false&share=false`}
				scrolling="no"
				frameBorder="0"
				className="relative w-full pointer-events-auto md:hidden"
				style={{
					border: "none",
					overflow: "hidden",
					bottom: "0px",
				}}
			></iframe>
		</div>
	);
};

/* Export
============================================================================= */

export default AcastMobile;
