import React, { useEffect } from "react";
import NavigationLink from "./navigationLink";

/* Navigation
============================================================================= */

const Navigation = ({ children }) => {
	// Config

	// Render
	return (
		<nav
			aria-label="Footer Navigation"
			className="md:w-1/3 w-full flex flex-col md:items-start items-center justify-start space-y-4"
		>
			<NavigationLink to="/about" label="About" />
			<NavigationLink to="/accessibility" label="Accessibility" />
			<NavigationLink to="/advertise" label="Advertise" />
			<NavigationLink to="/podcasts" label="Podcasts" />
			<NavigationLink to="/news" label="News" />
			<NavigationLink to="/sponsors" label="Sponsors" />
			<NavigationLink to="/contact" label="Contact" />
		</nav>
	);
};

/* Export
============================================================================= */

export default Navigation;
