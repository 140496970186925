import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Image from "gatsby-image";
import { kebabCase, getGatsbyImage } from "helpers";

/* Featured Sponsors
============================================================================= */

const FeaturedSponsors = () => {
	// Config
	const data = useStaticQuery(graphql`
		{
			allSanitySponsor {
				nodes {
					name
					website
					description
					image {
						image {
							asset {
								localFile {
									childImageSharp {
										fluid(maxWidth: 750) {
											src
											srcSet
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	// Render Sponsors
	const renderSponsors = () => {
		return data.allSanitySponsor.nodes.map((sponsor, i) => {
			return (
				<Link
					to="/sponsors/rode"
					key={sponsor.name}
					aria-label={sponsor.name}
					role="listitem"
					className="md:w-52 w-full"
				>
					<Image
						fluid={getGatsbyImage(sponsor.image)}
						alt={sponsor.image.alt}
						className="square-image shadow-lg duration-200 ease-in-out rounded-md transition-shadow group-focus outline-none"
						placeholderStyle={{
							filter: "blur(50px)",
						}}
						style={{
							overflow: "hidden",
						}}
						imgStyle={{
							objectFit: "contain",
							objectPosition: "50% 50%",
						}}
					/>
				</Link>
			);
		});
	};

	// Render
	return (
		<ul
			aria-label="Litmus Media Sponsors"
			role="list"
			className="container mx-auto bg-black w-full flex flex-row items-center justify-around padding-top horizontal-padding padding-bottom "
		>
			{renderSponsors()}
		</ul>
	);
};

/* Export
============================================================================= */

export default FeaturedSponsors;
