import React from "react";
import { Link } from "gatsby";
import {
	FaSpotify,
	FaRssSquare,
	FaFacebookSquare,
	FaTwitterSquare,
	FaShareAlt,
	FaInstagramSquare,
	FaYoutubeSquare,
	FaHome,
} from "react-icons/fa";
import { ImSoundcloud2 } from "react-icons/im";
import { BiWorld } from "react-icons/bi";
import { kebabCase, getGatsbyImage } from "helpers";

/* Person Socials
============================================================================= */

const PersonSocials = ({ host }) => {
	// Config

	// Render Website
	const renderWebsite = () => {
		try {
			if (host.website) {
				return (
					<a
						role="listitem"
						aria-label="Website"
						target="_blank"
						rel="noopener noreferrer"
						href={host.website}
					>
						<BiWorld color="black" size={40} />
					</a>
				);
			}
		} catch (error) {
			return null;
		}
	};

	// Render Facebook
	const renderFacebook = () => {
		try {
			if (host.facebook) {
				return (
					<a
						role="listitem"
						aria-label="Facebook"
						target="_blank"
						rel="noopener noreferrer"
						href={host.facebook}
					>
						<FaFacebookSquare color="black" size={40} />
					</a>
				);
			}
		} catch (error) {
			return null;
		}
	};

	// Render Twitter
	const renderTwitter = () => {
		try {
			if (host.twitter) {
				return (
					<a
						role="listitem"
						aria-label="Twitter"
						target="_blank"
						rel="noopener noreferrer"
						href={host.twitter}
					>
						<FaTwitterSquare color="black" size={40} />
					</a>
				);
			}
		} catch (error) {
			return null;
		}
	};

	// Render Instagram
	const renderInstagram = () => {
		try {
			if (host.instagram) {
				return (
					<a
						role="listitem"
						aria-label="Instagram"
						target="_blank"
						rel="noopener noreferrer"
						href={host.instagram}
					>
						<FaInstagramSquare color="black" size={40} />
					</a>
				);
			}
		} catch (error) {
			return null;
		}
	};

	// Render Youtube
	const renderYoutube = () => {
		try {
			if (host.youtube) {
				return (
					<a
						role="listitem"
						aria-label="Youtube"
						target="_blank"
						rel="noopener noreferrer"
						href={host.youtube}
					>
						<FaYoutubeSquare color="black" size={40} />
					</a>
				);
			}
		} catch (error) {
			return null;
		}
	};

	// Render Soundcloud
	const renderSoundcloud = () => {
		try {
			if (host.soundcloud) {
				return (
					<a
						role="listitem"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Soundcloud"
						href={host.soundcloud}
					>
						<ImSoundcloud2 color="black" size={36} />
					</a>
				);
			}
		} catch (error) {
			return null;
		}
	};

	// Render
	return (
		<ul
			role="list"
			aria-label="Socials"
			className="flex flex-row items-center justify-start pt-4 space-x-4"
		>
			{renderWebsite()}
			{renderFacebook()}
			{renderTwitter()}
			{renderInstagram()}
			{renderYoutube()}
			{renderSoundcloud()}
		</ul>
	);
};

/* Export
============================================================================= */

export default PersonSocials;
