import create from "zustand";

/* Use  Menu
============================================================================= */

const useMenu = create((set) => ({
	showMenu: false,
	ad: false,
	setAd: () => set((state) => ({ ad: !state.ad })),
	setShowMenu: (show) => set((state) => ({ showMenu: show })),
}));

/* Export
============================================================================= */

export default useMenu;
