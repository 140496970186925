import * as React from "react";

/* Acast
============================================================================= */

function Acast(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			aria-label="Acast"
			width="100%"
			height="30"
			width="30"
			viewBox="0 0 512 512"
			style={{ transformOrigin: "50% 50%", transform: "scale(1.25)" }}
			{...props}
		>
			<rect width={512} height={512} fill="#fff" rx="15%" />
			<path d="M256 64c98 0 179 73 191 168L295 444A192 192 0 0164 256c0-106 86-192 192-192z" />
			<path
				fill="#fff"
				d="M264 138c-70 0-109 29-109 59 0 24 18 39 47 39 27 0 45-10 45-24l-1-7c-1-1-1-4-1-7s1-14 18-14c19 0 19 16 19 24v42h-4c-93 2-141 25-141 68 0 35 31 58 79 58 28 0 56-14 68-33l93-134c0-34-37-71-113-71zm16 167c0 12-10 22-23 22s-21-7-21-20c0-15 10-30 40-33h5z"
			/>
		</svg>
	);
}

/* Export
============================================================================= */

export default Acast;
