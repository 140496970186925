// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-advertise-js": () => import("./../../../src/pages/advertise.js" /* webpackChunkName: "component---src-pages-advertise-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-newsletters-js": () => import("./../../../src/pages/newsletters.js" /* webpackChunkName: "component---src-pages-newsletters-js" */),
  "component---src-pages-podcasts-js": () => import("./../../../src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-sponsors-js": () => import("./../../../src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-zine-js": () => import("./../../../src/pages/zine.js" /* webpackChunkName: "component---src-pages-zine-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-episode-js": () => import("./../../../src/templates/episode.js" /* webpackChunkName: "component---src-templates-episode-js" */),
  "component---src-templates-episodes-js": () => import("./../../../src/templates/episodes.js" /* webpackChunkName: "component---src-templates-episodes-js" */),
  "component---src-templates-podcast-js": () => import("./../../../src/templates/podcast.js" /* webpackChunkName: "component---src-templates-podcast-js" */),
  "component---src-templates-podcast-transcript-js": () => import("./../../../src/templates/podcastTranscript.js" /* webpackChunkName: "component---src-templates-podcast-transcript-js" */),
  "component---src-templates-sponsor-js": () => import("./../../../src/templates/sponsor.js" /* webpackChunkName: "component---src-templates-sponsor-js" */),
  "component---src-templates-transcript-js": () => import("./../../../src/templates/transcript.js" /* webpackChunkName: "component---src-templates-transcript-js" */)
}

