import create from "zustand";

/* Use  Player
============================================================================= */

const usePlayer = create((set) => ({
	playing: false,
	episode: 0,
	loading: false,
	elapsed: 0,
	duration: 0,
	url:
		"https://embed.acast.com/our-place/thebaginthebox?accentColor=fff&bgColor=000&logo=false&secondaryColor=b4841f&subscribe=false&share=false",
	episodes: [
		{
			show: "rising-2021-podcast",
			episode: "rising2021podcasttrailer",
			showName: "Rising 2021 Podcast",
			episodeName: "Rising 2021 Podcast Trailer",
		},
		{
			show: "our-place",
			episode: "introducingourplace",
			showName: "Our Place",
			episodeName: "Introducing Our Place",
		},
		{
			show: "modern-australian-underground",
			episode: "introducingmodernaustralianunderground",
			showName: "Modern Australian Underground",
			episodeName: "Introducing Modern Australian Underground",
		},
		{
			show: "mont-icons",
			episode: "introducingmonticons",
			showName: "Mont Icons",
			episodeName: "Introducing Mont Icons",
		},
		{
			show: "divergent",
			episode: "introducingdivergent",
			showName: "Divergent",
			episodeName: "Introducing Divergent",
		},
		{
			show: "witness-box",
			episode: "introducingwitnessbox",
			showName: "Witness Box",
			episodeName: "Introducing Witness Box",
		},
	],
	setDuration: (duration) => set((state) => ({ duration })),
	setElapsed: (elapsed) => set((state) => ({ elapsed })),
	setPlaying: (playing) => set((state) => ({ playing })),
	setEpisode: (episode) => set((state) => ({ episode })),
	setLoading: (loading) => set((state) => ({ loading })),
	addEpisode: (episode) =>
		set((state) => {
			return {
				episodes: [...state.episodes, episode],
			};
		}),
}));

/* Export
============================================================================= */

export default usePlayer;
