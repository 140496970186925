import React, { useEffect } from "react";
import { Link } from "gatsby";

/* Navigation Link
============================================================================= */

const NavigationLink = ({ label, to }) => {
	// Config

	// Render
	return (
		<Link
			aria-label={label}
			className="font-body text-xl tracking-wider text-left text-white font-bold"
			to={to}
		>
			{label}
		</Link>
	);
};

/* Export
============================================================================= */

export default NavigationLink;
