import React, { useEffect } from "react";
import { Link } from "gatsby";
import NavigationLink from "./navigationLink";
import { FaSearch } from "react-icons/fa";
import { GrMail } from "react-icons/gr";

/* Navigation
============================================================================= */

const Navigation = ({ children }) => {
	// Config

	// Render
	return (
		<nav
			aria-label="Main"
			className="md:flex flex-row items-center justify-between hidden col-span-3 header-horizontal-padding"
		>
			<ul className="space-x-6 h-full flex flex-row items-center">
				<NavigationLink to="/podcasts" label="Podcasts" />
				<NavigationLink to="/about" label="About">
					<NavigationLink to="/about" label="About Us" />
					<NavigationLink to="/accessibility" label="Accessibility" />
					<NavigationLink to="/advertise" label="Advertise" />
					<NavigationLink to="/contact" label="Contact" />
					<NavigationLink to="/sponsors" label="Sponsors" />
				</NavigationLink>
				<NavigationLink to="/news" label="News" />
				<Link
					role="listitem"
					to="/newsletters"
					className="space-x-2 flex flex-row items-center h-full"
				>
					<GrMail color="white" size={25} />
					<NavigationLink to="/newsletters" label="Sign Up" />
				</Link>
			</ul>
			<ul className="space-x-6 flex flex-row items-center justify-end h-full">
				<li className="space-x-2 flex flex-row items-center h-full hidden">
					<FaSearch color="white" size={20} />
					<NavigationLink to="/search" label="Search" />
				</li>
				<Link
					role="listitem"
					to="/newsletters"
					className="space-x-2 flex flex-row items-center h-full hidden"
				>
					<GrMail color="white" size={25} />
					<NavigationLink to="/newsletters" label="Sign Up" />
				</Link>
			</ul>
		</nav>
	);
};

/* Export
============================================================================= */

export default Navigation;
