import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
	FaVimeo,
	FaSoundcloud,
	FaYoutube,
	FaInstagram,
	FaFacebookSquare,
	FaTwitterSquare,
} from "react-icons/fa";

/* Socials
============================================================================= */

const Socials = () => {
	// Config
	const data = useStaticQuery(graphql`
		{
			sanitySiteConfig {
				twitter_site
				youtube_site
				instagram_site
				facebook_site
			}
		}
	`);

	// Render
	return (
		<div
			role="group"
			className="flex md:flex-row flex-col items-center justify-start"
		>
			<span className="font-body text-2xl text-white font-semibold md:pb-0 pb-4">
				Follow Us
			</span>
			<ul
				role="list"
				className="flex flex-row items-center justify-start pl-8 space-x-6 md:pr-0 pr-10"
			>
				<li role="listitem" className="hidden">
					<a href="/" aria-label="Vimeo">
						<FaVimeo color="white" size={40} />
					</a>
				</li>
				<li role="listitem" className="hidden">
					<a href="/" aria-label="Soundcloud">
						<FaSoundcloud color="white" size={40} />
					</a>
				</li>
				<li role="listitem" className="hidden">
					<a
						href={data.sanitySiteConfig.youtube_site}
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Youtube"
					>
						<FaYoutube color="white" size={40} />
					</a>
				</li>
				<li role="listitem">
					<a
						href={data.sanitySiteConfig.instagram_site}
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Instagram"
					>
						<FaInstagram color="white" size={40} />
					</a>
				</li>
				<li role="listitem">
					<a
						href={data.sanitySiteConfig.facebook_site}
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Facebook"
					>
						<FaFacebookSquare color="white" size={40} />
					</a>
				</li>
				<li role="listitem">
					<a
						href={data.sanitySiteConfig.twitter_site}
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Twitter"
					>
						<FaTwitterSquare color="white" size={40} />
					</a>
				</li>
			</ul>
		</div>
	);
};

/* Export
============================================================================= */

export default Socials;
