import React, { useEffect } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Image from "gatsby-image";

/* Logo
============================================================================= */

const Logo = ({ image }) => {
	// Config

	// Render
	return (
		<Link to="/">
			<Image
				fluid={image}
				alt="Litmus Media, Brand Logo"
				className="md:w-60 w-32"
				objectFit="contain"
				objectPosition="50% 50%"
			/>
		</Link>
	);
};

/* Export
============================================================================= */

export default Logo;
