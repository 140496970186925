import React, { useEffect, useState } from "react";
import Socials from "./socials";
import FeaturedSponsors from "./featuredSponsors";
import Navigation from "./navigation";
import Copyright from "./copyright";
import axios from "axios";

/* Footer
============================================================================= */

const Footer = ({ children }) => {
	// Config
	const [email, setEmail] = useState("");

	// On Submit
	const onSubmit = async (e) => {
		e.preventDefault();
		const encode = (data) => {
			return Object.keys(data)
				.map(
					(key) =>
						encodeURIComponent(key) +
						"=" +
						encodeURIComponent(data[key])
				)
				.join("&");
		};
		await axios
			.post(
				"/",
				encode({
					email,
					"form-name": "newsletter",
				}),
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			)
			.then(() => {
				setEmail("");
				alert("Subscribed!");
			});
	};

	// Render
	return (
		<footer
			id={`footer`}
			role="contentinfo"
			className="relative w-full pt-12 pb-12 bg-black horizontal-padding"
		>
			<div className="container flex flex-col items-start justify-between mx-auto md:flex-row md:items-end horizontal-padding">
				<Navigation />
				<div className="flex flex-col items-center justify-center w-full pt-16 pb-4 md:items-end md:justify-between lg:w-2/3 md:pt-0 md:pb-0">
					<section
						role="region"
						aria-label="Newsletter"
						className="pb-12 lg:w-2/3"
					>
						<div>
							<h2
								role="heading"
								className="mb-4 text-4xl text-center text-white font-display md:text-right lg:pb-0"
							>
								Subscribe to the Litmus Media Newsletter
							</h2>
							<p className="hidden pt-2 pb-10 text-lg leading-relaxed tracking-wide text-center text-white font-body md:text-right">
								Receive articles about new and upcoming
								Australian underground music with insight into
								the future sounds of Australia.
							</p>
							<form
								method="post"
								data-netlify="true"
								name="newsletter"
								hidden
								netlify
							>
								<input
									type="hidden"
									name="form-name"
									value="newsletter"
								/>
								<input type="email" name="email" />
							</form>
							<form className="flex flex-col items-center justify-start w-full md:flex-row md:justify-end">
								<input
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder="Enter your Email"
									className="w-full px-4 py-3 mb-4 text-black bg-white rounded-md shadow-lg font-body md:mb-0"
								/>
								<button
									type="submit"
									onClick={onSubmit}
									className="w-full px-8 py-2 text-2xl text-black bg-white rounded-md shadow-lg md:ml-4 font-display md:w-auto"
								>
									Join
								</button>
							</form>
						</div>
					</section>
					<Socials />
				</div>
			</div>
			<Copyright />
			<p className="container mx-auto italic text-white font-body horizontal-padding">
				I respectfully acknowledge the Traditional Owners of the land on
				which we work, play and learn, and pay respect to the First
				Nations Peoples and their elders, past and present.
			</p>
			<FeaturedSponsors />
		</footer>
	);
};

/* Export
============================================================================= */

export default Footer;
