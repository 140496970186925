import React, { useMemo } from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";
import BlockContent from "@sanity/block-content-to-react";
import { kebabCase, getGatsbyImage, getDate } from "helpers";
import usePlayer from "state/usePlayer";
import { FaRegPlayCircle } from "react-icons/fa";
import { ImPause2, ImFileText2 } from "react-icons/im";
import { VscLoading } from "react-icons/vsc";
import _ from "lodash";

/* Episode Item
============================================================================= */

const EpisodeItem = ({ episode, episodeNumber, noTranscript = false }) => {
	// Config
	const {
		setEpisode,
		episodes,
		setPlaying,
		addEpisode,
		playing,
		setLoading,
		loading,
		episode: episodeIndex,
	} = usePlayer();

	// Render Playing
	const renderPlaying = () => {
		if (
			episodes[episodeIndex].episode === episode.episode_id &&
			episodes[episodeIndex].show === episode.podcast.podcast_id &&
			loading
		) {
			return (
				<>
					<VscLoading
						color="black"
						size={30}
						className="animate-spin"
					/>
					<span className="pl-2 font-semibold font-body">
						LISTEN NOW
					</span>
				</>
			);
		}
		if (
			episodes[episodeIndex].episode === episode.episode_id &&
			episodes[episodeIndex].show === episode.podcast.podcast_id &&
			playing
		) {
			return (
				<>
					<ImPause2 color="black" size={30} />
					<span className="pl-2 font-semibold font-body">
						LISTEN NOW
					</span>
				</>
			);
		}
		return (
			<>
				<FaRegPlayCircle color="black" size={30} />
				<span className="pl-2 font-semibold font-body">LISTEN NOW</span>
			</>
		);
	};

	// Render

	return (
		<li
			role="listitem"
			aria-label={episode.name}
			className="relative flex flex-col items-start justify-start w-full py-4 pl-5 pr-6 bg-gray-100 rounded-md outline-none group"
		>
			<div className="text-sm tracking-wider text-gray-900 uppercase font-body">
				<span className="font-semibold text-gold">
					{episode.podcast.name}
				</span>{" "}
				<span>&bull; {episode.episode_number} </span> &bull;{" "}
				<time>
					{episode.podcast.name === "RISING 2021 Podcast"
						? "5 MAY 2021"
						: getDate(episode.date)}
				</time>
			</div>
			<div className="flex flex-row items-center justify-start pt-2">
				<Link
					to={`/podcasts/${kebabCase(
						episode.podcast.name
					)}/${kebabCase(episode.name)}`}
					className="pb-2 text-2xl font-semibold tracking-wide font-display md:text-3xl hover:text-gold"
				>
					{episode.name}
				</Link>
			</div>
			<BlockContent
				blocks={episode._rawDescription}
				className="block-content-episode-item w-full pb-4 leading-relaxed font-body"
				renderContainerOnSingleChild
			/>
			<div className="flex flex-row items-center justify-start w-full">
				<div className="flex flex-row items-center justify-start mr-4 rounded-md outline-none group">
					<button
						aria-label="Listen Now"
						style={{ outline: "0px" }}
						className={`${
							loading ? "opacity-70 pointer-events-none" : ""
						} flex flex-row items-center justify-start focus outline-none`}
						onClick={() => {
							const player = document.getElementById(
								"acast-player"
							);
							if (
								episodes[episodeIndex].episode ===
									episode.episode_id &&
								episodes[episodeIndex].show ===
									episode.podcast.podcast_id &&
								playing
							) {
								setPlaying(false);
								const message = {
									eventName: "postmessage:do:pause",
								};
								player.contentWindow.postMessage(
									JSON.stringify(message),
									"*"
								);
								return;
							}
							let message;
							const index = _.findIndex(episodes, (el) => {
								return (
									el.show === episode.podcast.podcast_id &&
									el.episode === episode.episode_id
								);
							});
							if (index === 0 || index !== -1) {
								setEpisode(index);
								setLoading(true);
								message = {
									eventName: "postmessage:do:play",
									data: {
										show: episodes[index].show,
										episode: episodes[index].episode,
									},
								};
								setPlaying(true);
							} else {
								addEpisode({
									show: episode.podcast.podcast_id,
									episode: episode.episode_id,
									showName: episode.podcast.name,
									episodeName: episode.name,
								});
								setEpisode(episodes.length);
								setLoading(true);
								message = {
									eventName: "postmessage:do:play",
									data: {
										show: episode.podcast.podcast_id,
										episode: episode.episode_id,
									},
								};
								setPlaying(true);
							}
							player.contentWindow.postMessage(
								JSON.stringify(message),
								"*"
							);
						}}
					>
						{renderPlaying()}
					</button>
				</div>
				{!noTranscript ? (
					<Link
						to={`/podcasts/${kebabCase(
							episode.podcast.name
						)}/${kebabCase(episode.name)}/transcript`}
						className="relative flex flex-row items-center justify-start font-semibold font-body"
					>
						<span className="hidden mr-4 text-lg">&bull;</span>
						<ImFileText2 color="black" size={26} />
						<span className="pl-2 font-body">TRANSCRIPT</span>
					</Link>
				) : null}
			</div>
		</li>
	);
};

/* Export
============================================================================= */

export default EpisodeItem;
