import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import usePlayer from "state/usePlayer";

/* Acast
============================================================================= */

const Acast = ({ children }) => {
	// Config
	const { url, setLoading } = usePlayer();
	const data = useStaticQuery(graphql`
		{
			allSanityEpisode(
				sort: { order: DESC, fields: _createdAt }
				limit: 1
			) {
				nodes {
					podcast {
						podcast_id
					}
					episode_id
				}
			}
		}
	`);

	useEffect(() => {
		const player = document.getElementById("acast-player");
		window.addEventListener(
			"message",
			(event) => {
				if (event.data.eventName === "postmessage:on:play") {
					setLoading(false);
				}
			},
			false
		);
	}, []);

	// Render
	return (
		<iframe
			aria-hidden="true"
			id="acast-player"
			title="Embed Player"
			width="350px"
			height="96px"
			src={`https://embed.acast.com/rising-2021-podcast/theriverssingwithdeborahcheethamao-danielbrowning?accentColor=fff&bgColor=000&logo=false&secondaryColor=b4841f&subscribe=false&share=false`}
			scrolling="no"
			frameBorder="0"
			className="relative hidden md:block"
			style={{
				border: "none",
				overflow: "hidden",
				top: "0px",
			}}
		></iframe>
	);
};

/* Export
			src={`https://embed.acast.com/${data.allSanityEpisode.nodes[0].podcast.podcast_id}/${data.allSanityEpisode.nodes[0].episode_id}?accentColor=fff&bgColor=000&logo=false&secondaryColor=b4841f&subscribe=false&share=false`}
============================================================================= */

export default Acast;
