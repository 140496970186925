import React from "react";
import { Link } from "gatsby";

/* Copyright
============================================================================= */

const Copyright = () => {
	// Config

	// Render
	return (
		<div
			role="group"
			aria-label="Copyright, Terms of Service, Privacy Policy"
			className="container mx-auto flex md:flex-row flex-col items-center justify-between padding-top padding-bottom horizontal-padding"
		>
			<p className="font-body text-lg font-medium text-white md:pb-0 pb-4">
				© Litmus Media 2020
			</p>
			<div className="flex md:flex-row flex-col items-center md:justify-end justify-center md:space-x-8 md:space-y-0 space-y-4">
				<Link to="/terms" className="footer-navigation-link text-white">
					Terms of Service
				</Link>
				<Link
					to="/privacy"
					className="footer-navigation-link text-white"
				>
					Privacy Policy
				</Link>
			</div>
		</div>
	);
};

/* Export
============================================================================= */

export default Copyright;
