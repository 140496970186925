import React, { useEffect } from "react";
import Image from "gatsby-image";
import { useStaticQuery, graphql, Link } from "gatsby";
import useMenu from "state/useMenu";

/* Sidebar
============================================================================= */

const Sidebar = ({ children }) => {
	// Config
	const { ad } = useMenu();
	const data = useStaticQuery(graphql`
		{
			ad: file(relativePath: { eq: "ad.png" }) {
				childImageSharp {
					fluid(maxWidth: 1000) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
			adTwo: file(relativePath: { eq: "adTwo.png" }) {
				childImageSharp {
					fluid(maxWidth: 1000) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
			adRising: file(relativePath: { eq: "rising.jpeg" }) {
				childImageSharp {
					fluid(maxWidth: 1000, quality: 100) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
		}
	`);
	// Render
	return (
		<div className="relative hidden pl-12 bg-gray-100 lg:block">
			<div className="sticky top-0 w-full pb-10 pt-36">
				<Link
					to="/podcasts/rising-2021-podcast"
					className="flex flex-col items-center justify-center w-full mt-10 bg-gray-200 "
					style={{ height: "600px", width: "300px" }}
				>
					<Image
						fluid={data.adRising.childImageSharp.fluid}
						imgStyle={{
							objectFit: "cover",
							objectPosition: "50% 50%",
						}}
						style={{
							height: "100%",
							width: "100%",
						}}
					/>
				</Link>
				{ad ? (
					<a
						href="https://lulusmelb.com/"
						target="_blank"
						rel="noopener noreferrer"
						className="flex flex-col items-center justify-center hidden w-full mt-10 bg-gray-200 "
						style={{ height: "600px", width: "300px" }}
					>
						<Image
							fluid={data.ad.childImageSharp.fluid}
							imgStyle={{
								objectFit: "cover",
								objectPosition: "50% 50%",
							}}
							style={{
								height: "100%",
								width: "100%",
							}}
						/>
					</a>
				) : (
					<a
						href="https://montpublishing.com/"
						target="_blank"
						rel="noopener noreferrer"
						className="flex flex-col items-center justify-center hidden w-full mt-10 bg-gray-200 "
						style={{ height: "600px", width: "300px" }}
					>
						<Image
							fluid={data.adTwo.childImageSharp.fluid}
							imgStyle={{
								objectFit: "cover",
								objectPosition: "50% 50%",
							}}
							style={{
								height: "100%",
								width: "100%",
							}}
						/>
					</a>
				)}
			</div>
		</div>
	);
};

/* Export
============================================================================= */

export default Sidebar;
